.nav-item {
    width: calc(var(--nav-size)*0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}


.nav-item:hover {
    height: 65px;
}

.icon-button {
    --button-size: calc(var(--nav-size)*0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: rgb(45, 160, 93);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    cursor: pointer;
}

.icon-button:hover {
    filter: brightness(1.1);
}

.icon-button svg {
    fill: var(--text-color);
    width: 30px;
    height: 30px;
}

.icon-buttonDD {
    --button-size: calc(var(--nav-size)*0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: rgb(45, 160, 93);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    cursor: pointer;
}

.icon-buttonDD:hover {
    filter: brightness(1.1);
}

.icon-buttonDD svg {
    fill: var(--text-color);
    width: 30px;
    height: 30px;
}

.nav-itemDD {
    width: calc(var(--nav-size)*0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.nav-itemDD:hover {
    height: 65px;
}

.dropdownClass {
    cursor: pointer;
    max-height: 0px;
    opacity: 0;
    position: absolute;
    top: 58px;
    width: 50px;
    background-color: rgb(28, 122, 25);
    border: 1px solid rebeccapurple;
    border-radius: var(--border-radius);
    padding: 0.2rem;
    overflow: hidden;
}

.nav-itemDD:hover div{
    max-height: 1000px;
    opacity: 1;
    animation: load 1s forwards;
}

@keyframes load{
    0%{
        transform: translateX(450px) scale(4) rotate(-90deg);
    }
    100%{
        transform: translateX(0px) scale(1) rotatte(0);
    }
    
}