.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.25rem;
}

.menu-item:hover {
    background-color: rgba(0, 0, 0, 0.315);
}

.icon-right {
    margin-left: auto;
}