* {
  margin: 0;
}

.navibar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: flex-end;
  top: 0px;
  background-color: rgba(191, 63, 127, 1);
  max-height: 80px;
  font-family: 'Michroma', sans-serif;
  width: 100%;
}

.nav-link {
  padding:10px;
  margin-right: 80px;
  text-decoration: none;
  color: rgba(242, 242, 242, 1 );
  position: relative; 
}

.nav-link::after {
  content:'';
  height: 2px;
  width: 0px;
  position: absolute;
  background-color: rgba(242, 242, 242, 1 );
  bottom: 0px;
  left: 50%;
  transition: all ease-in-out .3s;
}
  
.nav-link:hover::after {
  width: 100%;
  left: 0;
}
  
.contactbar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  bottom: 0px;
  background-color: rgb(41, 41, 41);
  color: rgba(242, 242, 242, 1 );
  max-height: 80px;
  font-family: 'Michroma', sans-serif;
  width: 100%;
  overflow: hidden;
}

.contact-link {
  padding:10px;
  margin-right: 80px;
  text-decoration: none;
  color: rgba(242, 242, 242, 1 );
  position: relative; 
}

.welcome-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, rgb(35, 17, 117), rgba(42, 99, 99, 1));
  color: whitesmoke;
  text-shadow: -1px 1px rgb(1, 87, 1),
              1px 1px rgb(1, 87, 1),
              -1px -1px rgb(1, 87, 1),
              1px -1px rgb(1, 87, 1);
  
}


@media (max-width: 650px) {

  .navbar {
    justify-content: space-between;
    font-size: 90%;
  }
  
  .nav-link {
  padding:10px;
  margin-right: 10px;
  }

  .welcome-section {
  width: 100%;
  }
}

@media (max-width: 650px) {
  .navbar {
    justify-content: space-between;
    font-size: 90%;
  }
  
  .nav-link {
  padding:10px;
  margin-right: 10px;
  }
  .welcome-section {
      width: 100%;
  }
}

h1 {
  font-family: 'Orbitron', sans-serif;
}

.secondary-intro {
  font-family: 'Michroma', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.project-section {
  min-height: 100vh;
  display: flex; 
  flex-direction: column;
  align-items: center;
  background-image: url('./Images/binary.jpg');
  background-color: rgba(73, 89, 202, 1 );
  padding-bottom: 90px;
  text-shadow: -1px 1px rgb(1, 87, 1),
              1px 1px rgb(1, 87, 1),
              -1px -1px rgb(1, 87, 1),
              1px -1px rgb(1, 87, 1);
  
}

.project-items {
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
}


.project-tile {
  background: linear-gradient(45deg, rgb(35, 17, 117), rgba(42, 99, 99, 1));
  max-width: 200px;
  font-family: 'Michroma', sans-serif;
  text-decoration: none;
  text-align: center;
  font-size: 130%;
  color: whitesmoke;
  text-shadow: -1px 1px rgb(1, 87, 1),
              1px 1px rgb(1, 87, 1),
              -1px -1px rgb(1, 87, 1),
              1px -1px rgb(1, 87, 1);
  margin-bottom: 10px;
  box-shadow: 2px 2px 10px olivedrab;
  border-radius: 10px;
}

@media (min-width: 600px) {
  .project-items {
  flex-direction: row ;
  }
  .project-tile {
    max-width: 250px;
    margin-left: auto;
    margin-right: 10px;
  }
}

.project-tile:hover {
  border: 1px solid rgba(242, 242, 242, .9 );
}

.projectimg {
  max-width: 400px;
  width: 100%;
  max-height: 400px;
}

.project-title {
  padding: 15px;
}

.project-section-header {
  text-align: center;
  margin-top: 80px;
  font-family: 'Orbitron', sans-serif;
}

.contact-links-group {
  display: flex;
  justify-content: space-evenly;
}

.contact-links, h2 {
  text-decoration: none;
  color: rgba(242, 242, 242, 1 );
  padding: 20px;
  transition: all linear 0.1s;
}

.contact-links:hover {
  text-shadow: 1px 1px 1px green;
  filter: brightness(1.2);
  font-weight: bold;
 }
