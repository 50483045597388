.navbar {
    position: fixed;
    width: 100%;
    height: var(--nav-size);
    background-color: rgb(28, 122, 25);
    padding: 0 1rem;
    border-bottom: var(--border);
}

.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}